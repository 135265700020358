import React, { useRef, useEffect } from 'react';
import * as echarts from 'echarts/core';
import { SVGRenderer, SvgChart } from '@wuba/react-native-echarts';
import { PieChart } from 'echarts/charts';
import PropTypes from 'prop-types';

echarts.use([SVGRenderer, PieChart]);

const ChartComponent = ({ height, width, option }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    let chart;
    /* istanbul ignore next */
    if (chartRef.current !== null) {
      chart = echarts.init(chartRef.current, 'light', { renderer: 'svg', width, height });
      chart.setOption(option);
    }
    return () => chart?.dispose();
  }, [width, height, option]);

  return <SvgChart useRNGH ref={chartRef} />;
};

ChartComponent.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  option: PropTypes.object.isRequired,
};

export default ChartComponent;
